<template>
  <div class="main">
    <div class="page-bg"></div>

    <div class="animation-wrapper">
      <div class="particle particle-1"></div>
      <div class="particle particle-2"></div>
      <div class="particle particle-3"></div>
      <div class="particle particle-4"></div>
    </div>
    <v-img
      lazy-src="https://picsum.photos/id/11/10/6"
      max-height="400"
      width="100%"
      cover
      src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btcBg.jpeg?alt=media&token=89e802e6-37b5-4fdc-8560-6b4f23aa938f"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-col cols="12" md="6">
          <h1 class="text-h3 font-weight-bold mb-4 textWhite">About Us</h1>
          <v-card-actions>
            <v-icon color="#d4b506">mdi-home</v-icon>
            <v-btn text color="#fff" @click="navigate('signup')"> Home </v-btn>
            <div class="textWhite">/ About Us</div>
          </v-card-actions>
        </v-col>
        <v-col cols="12" md="6"> </v-col>
      </v-row>
    </v-img>
    <v-row class="mt-10">
      <v-col cols="12" md="6">
        <div class="pa-5">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <h1 class="text-h3 font-weight-bold mb-4 textWhite">About Us</h1>
              <h5 class="subheading font-weight-normal textWhite">
                An Industry Leader with Fully Automated Mining Rigs, Providing
                the Most Convenient Platform for Investors to get Share-profits
                from Well Organized Pool Mines Built for Profiting. Not Only do
                We Guarantee the Fastest and Most Exciting returns on
                investments, We also Guarantee Security on Every Investment.
              </h5>
              <v-btn class="mt-7" x-large color="#d4b506" light>
                Join Us Now
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          lazy-src="https://picsum.photos/id/11/10/6"
          height="250"
          width="100%"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btc-removebg-preview.png?alt=media&token=9195e22f-019c-4625-9c0a-254979e568f3"
        />
      </v-col>
    </v-row>
    <div class="pa-5 ma-5">
      <h1 class="text-h2 text-center font-weight-bold mb-4 textWhite">
        Our Expert <span class="goldColor">Team Members</span>
      </h1>
      <h3 class="subheading font-weight-light textWhite text-center mb-7">
        We have a great team including our Mangers, Technicians and Engineers
        always working <br />
        hard for your profiting.
      </h3>
      <TeamMember />
    </div>
    <div class="ma-5 card">
      <h1 class="text-h2 text-center font-weight-bold mb-4 textWhite">
        Frequently Asked <span class="goldColor">Questions</span>
      </h1>
      <h5 class="subheading font-weight-normal textWhite text-center">
        We answered some of your Frequently Asked Questions regarding our
        platform. <br />If you have any questions unanswered or need further
        assistance please click on the Live Chat<br />
        button at the bottom right corner and an agent would be available to
        clarify you further.
      </h5>
      <div class="pa-5 mt-7">
        <v-row justify="center">
          <v-expansion-panels inset dark>
            <v-expansion-panel>
              <v-expansion-panel-header
                >When can I deposit/withdraw from my investment
                account?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Deposits are available at any time as you can always add more
                plans regardless of your current plan. Withdrawals are also
                available at any time once your mining time for the plan chosen
                is complete. The available amount is always shown on your
                dashboard.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                When can I deposit/withdraw from my investment account?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Deposits are available at any time as you can always add more
                plans regardless of your current plan. Withdrawals are also
                available at any time once your mining time for the plan chosen
                is complete. The available amount is always shown on your
                dashboard.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How do i check my account balance?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can see this anytime on your accounts dashboard. You can see
                this anytime on your accounts dashboard.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                I forgot my password what should i do?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Visit the password reset page, type in your email address and
                click the `Reset` button. Visit the password reset page, type in
                your email address and click the `Reset` button.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How will i know that the withdrawal has been successful?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can withdraw the full amount of your Available balance at
                any time as long as the mining time for your plan is complete.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How much can i withdraw
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can withdraw the full amount of your Available balance as
                any time as long as the mining time for your plan is complete.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div>
    </div>
    <v-footer color="black" class="footer">
      <v-row justify="center" no-gutters class="pb-7">
        <v-col class="py-4 text-center white--text" cols="12">
          <div class="text-center">
            <h1 class="white--text">
              <v-icon large color="#d4b506">mdi-bitcoin</v-icon>
              LXGMiners
            </h1>
          </div>
        </v-col>
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn>
        <v-col class="py-4 white--text" cols="12">
          © {{ new Date().getFullYear() }} —
          <strong
            ><span class="goldColor">LXGMINERS INC.</span> All rights
            reserved</strong
          >
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
 
<script>
import TeamMember from "../components/TeamMember.vue";

export default {
  components: {
    TeamMember,
  },
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    links: ["Home", "Privacy & Policy", "Terms & Conditions"],
  }),
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid #d4b506 !important;
}
.card {
  min-height: 600px;
  background-color: rgb(5, 5, 5, 0.8) !important;
  padding: 55px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  flex: 1;
  background-color: black;
  height: 100vh;
}
.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.section-2 {
  background-color: rgb(5, 5, 5, 0.8) !important;
  margin: 5em;
}
.goldColor {
  color: #d4b506 !important;
}
.textWhite {
  color: white;
}
/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

/* Styles */
html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  overflow: hidden;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

.page-wrapper {
  text-align: center;
  color: #fff;
  z-index: 2;
}

h4 {
  color: #fff;
  font-family: Monoton;
  -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
  -moz-animation: neon1 1.5s ease-in-out infinite alternate;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #ff1177;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
</style>
