<template>
  <v-container class="pa-4 text-center">
    <v-row class="fill-height" align="center" justify="center">
      <v-col v-for="(item, i) in items" :key="i" cols="12" md="4">
        <v-hover v-slot="{ hover }">
          <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
            <v-img :src="item.img" height="255px"></v-img>
            <div class="font-weight-bold text-h6 mb-2">{{ item.title }}</div>
            <h3 class="text-h5 mb-2">{{ item.text }}</h3>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
  <script>
export default {
  data: () => ({
    icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
    items: [
      {
        title: "Lewis Dos Santos",
        text: `Executive Director`,
        subtext: "Newly released songs. Updated daily.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-2ace7f490ac15af6138f886c5a20824e.jpg?alt=media&token=e7865393-7cd7-4b2b-bead-2b7e73d5f938",
      },
      {
        title: "Damian Randolph.",
        text: "Finance/Accounts Manager",
        subtext: "Lose yourself in rock tunes.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-575583d635a62bb90f7f8871daf24ae0.jpg?alt=media&token=4bd8e03e-2864-4f09-affe-12296370e25f",
      },
      {
        title: "Tend Francis",
        text: "Mining Technician",
        subtext: "Chill beats to mellow you out.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-65a791572f647996b0e5f0f7a44d0886.jpg?alt=media&token=901caf30-625e-4115-827d-59eda22b1c60",
      },
      {
        title: "Sam Rowie",
        text: `Mining Engineer`,
        subtext: "Newly released songs. Updated daily.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-a26d74e1612b5f6839d9b76a1e493a88.jpeg?alt=media&token=40f27e0c-ce5c-45ee-a2a6-39bcac9d6a65",
      },
      {
        title: "Fahad Bin Faiz",
        text: "Mining Rigs Technician",
        subtext: "Lose yourself in rock tunes.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-bb9d24452ab215d6ff542ef34b071187.jpg?alt=media&token=9b45328a-50ca-44f7-9b6e-a021d2a35a93",
      },
      {
        title: "Juna Seon",
        text: "Head Finance Department",
        subtext: "Chill beats to mellow you out.",
        img: "https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/avatar-dc2002bf5ef56b4890c2bb9c5bbc44e9.jpg?alt=media&token=f53f5fa3-fa88-45f2-b792-a378190d830d",
      },
    ],
    transparent: "rgba(255, 255, 255, 0)",
  }),
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>